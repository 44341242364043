@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-rescuablue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 241 249 / var(--tw-bg-opacity));
}
.bg-rescuablue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(203 225 243 / var(--tw-bg-opacity));
}
.bg-rescuablue-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(177 209 236 / var(--tw-bg-opacity));
}
.bg-rescuablue-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(149 194 229 / var(--tw-bg-opacity));
}
.bg-rescuablue-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(119 178 223 / var(--tw-bg-opacity));
}
.bg-rescuablue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(84 163 216 / var(--tw-bg-opacity));
}
.bg-rescuablue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(70 135 179 / var(--tw-bg-opacity));
}
.bg-rescuablue-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(56 108 143 / var(--tw-bg-opacity));
}
.bg-rescuablue-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(42 82 108 / var(--tw-bg-opacity));
}
.bg-rescuablue-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(29 57 76 / var(--tw-bg-opacity));
}
.from-rescuablue-50 {
  --tw-gradient-from: #e5f1f9;
  --tw-gradient-to: rgb(229 241 249 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-rescuablue-100 {
  --tw-gradient-from: #cbe1f3;
  --tw-gradient-to: rgb(203 225 243 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-rescuablue-200 {
  --tw-gradient-from: #b1d1ec;
  --tw-gradient-to: rgb(177 209 236 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-rescuablue-300 {
  --tw-gradient-from: #95c2e5;
  --tw-gradient-to: rgb(149 194 229 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-rescuablue-400 {
  --tw-gradient-from: #77b2df;
  --tw-gradient-to: rgb(119 178 223 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-rescuablue-500 {
  --tw-gradient-from: #54a3d8;
  --tw-gradient-to: rgb(84 163 216 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-rescuablue-600 {
  --tw-gradient-from: #4687b3;
  --tw-gradient-to: rgb(70 135 179 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-rescuablue-700 {
  --tw-gradient-from: #386c8f;
  --tw-gradient-to: rgb(56 108 143 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-rescuablue-800 {
  --tw-gradient-from: #2a526c;
  --tw-gradient-to: rgb(42 82 108 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-rescuablue-900 {
  --tw-gradient-from: #1d394c;
  --tw-gradient-to: rgb(29 57 76 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.to-rescuablue-50 {
  --tw-gradient-to: #e5f1f9;
}
.to-rescuablue-100 {
  --tw-gradient-to: #cbe1f3;
}
.to-rescuablue-200 {
  --tw-gradient-to: #b1d1ec;
}
.to-rescuablue-300 {
  --tw-gradient-to: #95c2e5;
}
.to-rescuablue-400 {
  --tw-gradient-to: #77b2df;
}
.to-rescuablue-500 {
  --tw-gradient-to: #54a3d8;
}
.to-rescuablue-600 {
  --tw-gradient-to: #4687b3;
}
.to-rescuablue-700 {
  --tw-gradient-to: #386c8f;
}
.to-rescuablue-800 {
  --tw-gradient-to: #2a526c;
}
.to-rescuablue-900 {
  --tw-gradient-to: #1d394c;
}
.text-rescuablue-50 {
  --tw-text-opacity: 1;
  color: rgb(229 241 249 / var(--tw-text-opacity));
}
.text-rescuablue-100 {
  --tw-text-opacity: 1;
  color: rgb(203 225 243 / var(--tw-text-opacity));
}
.text-rescuablue-200 {
  --tw-text-opacity: 1;
  color: rgb(177 209 236 / var(--tw-text-opacity));
}
.text-rescuablue-300 {
  --tw-text-opacity: 1;
  color: rgb(149 194 229 / var(--tw-text-opacity));
}
.text-rescuablue-400 {
  --tw-text-opacity: 1;
  color: rgb(119 178 223 / var(--tw-text-opacity));
}
.text-rescuablue-500 {
  --tw-text-opacity: 1;
  color: rgb(84 163 216 / var(--tw-text-opacity));
}
.text-rescuablue-600 {
  --tw-text-opacity: 1;
  color: rgb(70 135 179 / var(--tw-text-opacity));
}
.text-rescuablue-700 {
  --tw-text-opacity: 1;
  color: rgb(56 108 143 / var(--tw-text-opacity));
}
.text-rescuablue-800 {
  --tw-text-opacity: 1;
  color: rgb(42 82 108 / var(--tw-text-opacity));
}
.text-rescuablue-900 {
  --tw-text-opacity: 1;
  color: rgb(29 57 76 / var(--tw-text-opacity));
}

#wait:after {
  animation: dots 2s linear infinite;
  content: '0%';
  font-size: 40px;
}

html {scroll-behavior: smooth;}
@keyframes dots {

  0%,
  20% {
    content: '20%';
  }

  40% {
    content: '40%';
  }

  60% {
    content: '60%';
  }

  90%,
  100% {
    content: '100%';
  }
}

/* Full screen search */
*:focus {
  border: none;
  resize: none;
  outline: none;
}

#search {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translate(0px, -100%) scale(0, 0);
  -moz-transform: translate(0px, -100%) scale(0, 0);
  -o-transform: translate(0px, -100%) scale(0, 0);
  -ms-transform: translate(0px, -100%) scale(0, 0);
  transform: translate(0px, -100%) scale(0, 0);
  opacity: 0;
}

#search input[type="search"] {
  position: absolute;
  top: 50%;
  width: 100%;
  color: #fff;
  background: rgba(0, 0, 0, 0);
  font-size: 60px;
  font-weight: 300;
  text-align: center;
  border: 0px;
  margin: 0px auto;
  margin-top: -51px;
  padding-left: 30px;
  padding-right: 30px;
  outline: none;
  --tw-ring-color: transparent
}

#search .search-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 61px;
  margin-left: -45px;
  background-color: limegreen;
  border: black;
}

#search .close {
  position: fixed;
  top: 15px;
  right: 15px;
  color: #fff;
  background-color: limegreen;
  border-color: green;
  opacity: 1;
  padding: 0px 13px;
  font-size: 27px;
  border-radius: 5px;
}

#search.open {
  -webkit-transform: translate(0px, 0px) scale(1, 1);
  -moz-transform: translate(0px, 0px) scale(1, 1);
  -o-transform: translate(0px, 0px) scale(1, 1);
  -ms-transform: translate(0px, 0px) scale(1, 1);
  transform: translate(0px, 0px) scale(1, 1);
  opacity: 1;
}

.active-tab::after {
  content: '';
  position: absolute;
  left: 99%;
  top: 50%;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid rgb(148 163 184);
  clear: both;
  transform: translateY(-50%);
}

/* nft page style start */

@font-face {
  font-family: "Poppins-Regular";
  src: url(/assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "Poppins-Bold";
  src: url(/assets/fonts/Poppins-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url(/assets/fonts/Poppins-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: "Poppins-Medium";
  src: url(/assets/fonts/Poppins-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: "Poppins-Black";
  src: url(/assets/fonts/Poppins-Black.ttf);
  font-weight: 500;
}

@font-face {
  font-family: "Nexa";
  src: url(/assets/fonts/Nexa-Regular.otf);
  font-weight: bold;
}

@font-face {
  font-family: "Nexa-Bold";
  src: url(/assets/fonts/Nexa-Bold.otf);
  font-weight: bold;
}

.poppins-regular {
  font-family: "Poppins-Regular";
}

.poppins-bold {
  font-family: "Poppins-Bold";
}

.poppins-semi-bold {
  font-family: "Poppins-SemiBold";
}

.poppins-medium {
  font-family: "Poppins-Medium";
}

.poppins-black {
  font-family: "Poppins-Black";
}

 .nftName {
    overflow: visible;
  }
.gradientSec::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #54a3d8 20%, white 100%);
  clip-path: ellipse(78% 800px at 50% 29%);
  z-index: -1;
}

/* media query for mobile */
@media (max-width: 450px) {
  .gradientSec::before {
    clip-path: ellipse(200% 800px at 50% 850px);
    background: linear-gradient(to bottom, #54a3d8 20%, white 100%);
  }
}

@media (min-width: 451px) {
  .gradientSec::before {
    clip-path: ellipse(150% 800px at 50% 850px);
    background: linear-gradient(to bottom, #54a3d8 20%, white 100%);
  }
}

@media (min-width: 461px) {
  .gradientSec::before {
    clip-path: ellipse(150% 790px at 50% 850px);
    background: linear-gradient(to bottom, #54a3d8 22%, white 100%);
  }
}

@media (min-width: 570px) {
  .gradientSec::before {
    clip-path: ellipse(150% 780px at 50% 850px);
    background: linear-gradient(to bottom, #54a3d8 17%, white 100%);
  }
}

@media (min-width: 680px) {
  .gradientSec::before {
    clip-path: ellipse(114% 750px at 50% 850px);
    background: linear-gradient(to bottom, #54a3d8 17%, white 100%);
  }
}

@media (min-width: 850px) {
  .gradientSec::before {
    clip-path: ellipse(114% 722px at 50% 850px);
    background: linear-gradient(to bottom, #54a3d8 20%, white 100%);
  }
}

@media (min-width: 1000px) {
  .gradientSec::before {
    clip-path: ellipse(82% 676px at 50% 850px);
    background: linear-gradient(to bottom, #54a3d8 11%, white 100%);
  }
}

@media (min-width: 1360px) {
  .gradientSec::before {
    clip-path: ellipse(60% 676px at 50% 850px);
    background: linear-gradient(to bottom, #54a3d8 20%, white 100%);
  }
}

@media (min-width: 1630px) {
  .gradientSec::before {
    clip-path: ellipse(60% 630px at 50% 850px);
    background: linear-gradient(to bottom, #54a3d8 20%, white 30%);
  }
}

.roadmap {
  width: 75%;
}

/* media query for mobile */
@media (max-width: 1000px) {
  .roadmap {
    width: 100%;
  }
}

.shape:before {
  position: absolute;
  content: "";
  height: 30px;
  top: -30px;
  left: 3%;
  width: 6%;
  border-left: 5px solid #566298;
  -webkit-transform: skew(-45deg);
  -moz-transform: skew(-45deg);
  transform: skew(-45deg);
  border-top: 4px solid #566298;
}

/* .shape-hori-one:before {
  position: absolute;
  content: "";
  height: 30px;
  top: -30px;
  left: 17%;
  width: 3%;
  border-top: 4px solid #566298;
}

.shape-hori-two:before {
  position: absolute;
  content: "";
  height: 30px;
  top: -30px;
  left: 27.5%;
  width: 3%;
  border-top: 4px solid #566298;
}

.shape-hori-three:before {
  position: absolute;
  content: "";
  height: 30px;
  top: -30px;
  left: 40%;
  width: 3%;
  border-top: 4px solid #566298;
}

.shape-hori-four:before {
  position: absolute;
  content: "";
  height: 30px;
  top: -30px;
  left: 47.5%;
  width: 3%;
  border-top: 4px solid #566298;
} */

.globe {
  width: 40%;
  top: -70px;
  left: 50%;
  transform: translateX(-50%);
}

/* media query for mobile */
@media (max-width: 1254px) {
  .globe {
    top: -40px;
  }
}

.type-of-nft-1 {
  margin-left: 0px;
}

.type-of-nft-2 {
  margin-left: -150px;
}

.type-of-nft-3 {
  margin-left: -300px;
}

/* media query for mobile */
@media (max-width: 1265px) {
  .type-of-nft-1,
  .type-of-nft-2,
  .type-of-nft-3 {
    margin-left: 0px;
  }
}

/* nft page style end */

/* modal start */

.modal_hidden { /* added to support Login modal, but this should be done another way, so delete this later TODO */
  display: none;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: #ffffffb9;
}

.modal-page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  padding: 4% 0 0;
  margin: auto;
}

.close_icon {
  position: relative;
  text-align: end;
}

.close_icon i {
  position: absolute;
  top: 27px;
  right: 40px;
  z-index: 999;
  font-size: 30px;
  font-weight: 100;
  color: #272727;
  transition: all 0.3s ease;
}

.close_icon i:hover {
  transform: rotate(360deg);
}

.form {
  position: relative;
  z-index: 1;
  background: #ffffff;
  margin: 0 auto 100px;
  padding: 55px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  border-radius: 18px;
}

.form .heading h1 {
  text-align: center;
  font-size: 70px;
  color: #2c373d;
  font-weight: 700;
}

.important_links {
  display: flex;
  justify-content: space-evenly;
  font-size: 13px;
}

.important_links p {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: auto;
  font-family: "Poppins", sans-serif;
  color: #00a0ff;
}

.important_links p:hover {
  color: #52b8f5;
  cursor: pointer;
}

.form input {
  font-family: "Nexa", sans-serif;
  outline: 0;
  font-size: 18px;
  width: 100%;
  border: none;
  margin: 0 0 15px;
  padding: 15px 15px 7px 15px;
  box-sizing: border-box;
  border-bottom: 1px solid #5d9bc6;
  font-weight: 700;
  color: black;
}

.form button {
  font-family: "Nexa", sans-serif;
  /* text-transform: uppercase; */

  outline: 0;
  background: #ffffff;
  border: 0;
  padding: 6px 24px 6px 24px;
  color: #2c373d;
  font-size: 20px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  border-radius: 26px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  font-weight: 700;
}

.form button.main_btn {
  background: #00a0ff;
  color: #ffffff;
}

.form .message {
  margin: 15px 0 0;
  margin-top: 10px;
  font-family: "Poppins", sans-serif;
}

.form .message a {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.form .register-form {
  display: none;
}

body {
  font-family: "Poppins", sans-serif;
  font-family: "Nexa", sans-serif;
}

.error input[type="checkbox"] {
  outline: 2px solid #5d9bc6;
}

.error span {
  font-family: "Poppins", sans-serif;
}

/* modal end */

.main-menu:hover+.main-menu-top {
  display: block;
}

.main-menu:hover+body {
  display: none;
}

.main-menu-top:hover {
  display: block;
}

/* tab styling for mobile */
.tab-mobile {
  top: -76px;
}

@media (max-width: 1024px) {
  .tab-mobile {
    top: 0px;
  }
}

.transaction-full {
  width: 70%;
}

@media (max-width: 1024px) {
  .transaction-full {
    width: 100%;
  }

  .login-btn:hover,
  .signup-btn:hover {
    opacity: .6;
  }

  .login-btn,
  .signup-btn {
    padding: 12px;
    font-size: 15px;
    width: 100%;
    border-radius: 0px;
    height: unset;
  }
}

@media screen and (max-width: 600px) {
  .modal-page {
    width: 350px;
  }

  .form .heading h1 {
    font-size: 40px;
    line-height: 1.2;
  }

  .form {
    padding: 45px 30px;
  }

  button.send-password-text {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .modal-page {
    width: 300px;
  }

  .form .heading h1 {
    font-size: 30px !important;
    line-height: 1.2;
  }

  .form {
    padding: 35px 20px;
  }
}

.form input[type="checkbox"] {
  width: 15px !important;
  height: 15px;
  padding: 0;
  outline: 0 !important;
  margin: 6px 6px 6px;
}

.wpb_wrapper {
  position: absolute;
  top: -190px;
  width: 100%;
  height: 1000px;
  margin-left: -20px;
}

@media screen and (max-width: 1260px) {
  .wpb_wrapper {
    width: 100%;
    height: 900px;
  }
}

@media screen and (max-width: 1100px) {
  .wpb_wrapper {
    top: -170px;
    width: 100%;
    height: 800px;
  }
}

@media screen and (max-width: 860px) {
  .wpb_wrapper {
    top: -148px;
    width: 100%;
    height: 700px;
  }
}

@media screen and (max-width: 772px) {
  .wpb_wrapper {
    top: -103px;
    width: 100%;
    height: 500px;
  }
}

@media screen and (max-width: 618px) {
  .wpb_wrapper {
    width: 100%;
    height: 400px;
  }
}

@media screen and (max-width: 500px) {
  .wpb_wrapper {
    top: -68px;
    width: 100%;
    height: 300px;
  }
}

@media screen and (max-width: 400px) {
  .wpb_wrapper {
    top: -40px;
    width: 100%;
    height: 200px;
  }
}

body {
  overflow-x: hidden;
}

/* padding in container */

@media (max-width: 640px) {
  .container {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: 768px) {
  .container {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (min-width: 1024px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 1280px) {
  .container {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (min-width: 1536px) {
  .container {
    padding-left: 50px;
    padding-right: 50px;
  }
}

/* JN ADDED: for dashboard.tsx, give the nickname change input a border */
input.walletname {
  outline: 1px solid gray;
}
/* END JN ADDED: for dashboard.tsx, give the nickname change input a border */

/* JN ADDED: for index.tsx, bring selected EPC to the fore (push others back) */
#types-of-nft div {
  transform: scale(0.75);
  opacity: 0.7;
  transition: all 0.5s;
}

#types-of-nft div.selected {
  opacity: 1;
  transform: scale(1);
}
/* END JN ADDED: for index.tsx, bring selected EPC to the fore (push others back) */
